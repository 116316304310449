<template>
  <div class="advanced-management-products-cloning-form">
    <!-- Price options in dialog -->
    <VuetifyDialog
      :content-component="priceOptionsDialogComponent"
      :content-component-props="priceOptionsDialogProps"
      title="Tipos de precios"
      :visible="priceOptionsDialogVisible"
      :hide-action-buttons="true"
      @onEventComponent="handlePriceOptionsDialogEvent"
    />
    <!-- Loading -->
    <VuetifyContentLoading :loading="processingRequest" />
    <template v-if="!processingRequest">
      <!-- VuetifyToolBar -->
      <VuetifyToolBar :title="toolBarTitle" />
      <!-- Content -->
      <v-container>
        <CardContainer>
          <v-form novalidate @submit.prevent="onSubmit">
            <!-- Nombre categoría -->
            <CardContainerElement v-if="isCategoryCloning" width-text="4" width-content="8">
              <template v-slot:title>Categoría</template>
              <template v-slot:subtitle>
                Este es el valor (nombre) de la nueva categoría dentro de la carta o menú que desea
                clonar
              </template>
              <template v-slot:content>
                <v-text-field
                  v-model="$v.formFields.categoryName.$model"
                  label="Nombre"
                  type="text"
                  outlined
                  dense
                  color="grey"
                  :hide-details="!checkFieldErrors('categoryName').length"
                  :error-messages="checkFieldErrors('categoryName')"
                  @input="touchField('categoryName')"
                  @blur="touchField('categoryName')"
                />
              </template>
            </CardContainerElement>
            <!-- Tabla de productos -->
            <CardContainerElement width-text="4" width-content="8">
              <template v-slot:title> Productos </template>
              <template v-slot:subtitle>
                Solo los productos seleccionados de la tabla, serán clonados a la nueva categoría.
                <v-alert text dense type="info" class="mt-2 caption">
                  {{ infoText }}
                </v-alert>
              </template>
              <template v-slot:content>
                <AdvancedManagementProductsDataTable
                  :headers="headers"
                  :items="$v.formFields.items.$model"
                  :button-edit-prices="!isMenu"
                  @onChangeSelectedItems="handleChangeSelectedItems"
                  @onClickButtonEditPrices="handleClickButtonEditPrices"
                  @onItemPricesChanges="handleItemPricesChanges"
                />
              </template>
            </CardContainerElement>
            <!-- action buttons -->
            <FormButtons
              accept-button-text="Clonar"
              accept-button-type="button"
              :accept-button-loading="formProcessing"
              @onClickAcceptButton="handleAcceptButton"
              @onClickCancelButton="handleCancelButton"
            />
          </v-form>
        </CardContainer>
      </v-container>
    </template>
  </div>
</template>

<script>
// Constants
import { ADDONS, MENUS_TYPES } from '@/constants'
import { ACTION_TYPE } from '../constants'
// Components
import VuetifyDialog from '@/components/ui/VuetifyDialog'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import FormButtons from '@/components/ui/FormButtons'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import AdvancedManagementProductsDataTable from '../components/elements/AdvancedManagementProductsDataTable'
import AdvancedManagementPriceOptions from '../components/elements/AdvancedManagementPriceOptions'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
// Services
import { createCategory, getCategoryById, getParentCategoryByChildId } from '@/services/category'
import { getEveryDishesByCategoryId, updateDishById } from '@/services/dish'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { cloneDeep, get, isNil } from 'lodash'

export default {
  name: 'AdvancedManagementProductsCloningForm',
  components: {
    FormButtons,
    VuetifyContentLoading,
    VuetifyToolBar,
    VuetifyDialog,
    CardContainer,
    CardContainerElement,
    AdvancedManagementProductsDataTable
  },
  mixins: [addonsMixin, formMixin, uiMixin, validationMixin],
  data() {
    return {
      // Url params
      sourceCategoryId: this.$route.params.sourceCategory || null, // Desde donde extraigo los datos
      targetCategoryId: this.$route.params.targetCategory || null, // Donde deseo almacenarlos
      type: this.$route.params.type || ACTION_TYPE.category, // cloned type
      // Form
      formFields: {
        categoryName: null,
        items: []
      },
      formFieldsValidations: {
        categoryName: {
          required: 'Debes indicar un nombre para la categoría'
        },
        items: {
          prices: {
            withValues: 'No puedes dejar precios sin valor'
          }
        }
      },
      formDefaultError:
        'La carta destino donde se desea clonar los productos, no admite los precios indicados.',
      // Data table
      headers: [
        {
          text: 'Nombre',
          align: 'start',
          value: 'name',
          width: '40%'
        },
        {
          text: 'Precios',
          align: 'left',
          value: 'price',
          width: '60%'
        }
      ],
      // Diáĺogo de tipos de precios
      priceOptionsDialogVisible: false,
      priceOptionsDialogProps: null,
      // Others
      isMenu: false,
      isTAD: false,
      processingRequest: true,
      sourceCategoryData: null,
      sourceCategoryDishesData: [],
      targetCategoryData: null,
      selectedItems: []
    }
  },
  computed: {
    ...mapGetters('place', ['placeData', 'areThereAdditionalLanguages']),
    /**
     * Obtiene el título de la ToolBar
     *
     * @return {string} - título toolbar
     */
    toolBarTitle() {
      return this.type === ACTION_TYPE.category ? 'Clonar categoría' : 'Clonar productos'
    },
    /**
     * ¿Se trata de una clonación de categorías?
     *
     * @return {Boolean}
     */
    isCategoryCloning() {
      return this.type === ACTION_TYPE.category
    },
    /**
     * Obtiene el texto que se mostrará en el cabecera de la tabla
     *
     * @return {string}
     */
    infoText() {
      return !this.isMenu
        ? 'Recuerda que también puedes modificar los precios de los productos para la nueva categoría a crear'
        : 'Estas intentando clonar una categoría dentro de un "menú", por lo tanto todos los productos de dicho "menú" aparecerán sin precio dentro de este'
    },
    /**
     * Obtiene el componente de "opciones de precio"
     *
     * @return {Object}
     */
    priceOptionsDialogComponent() {
      return AdvancedManagementPriceOptions
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * When the user must click on accept button
     */
    handleAcceptButton() {
      // "Tocamos" todos los campos seleccionados
      const selectedItems = this.getSelectedItems()

      // Repasamos los campos disponibles de la tabla y le
      // establecemos el error si es necesario
      if (selectedItems.length > 0) {
        this.formFields.items.forEach((item, index) => {
          const field = `items.$each.${index}.prices`
          if (selectedItems.find((selectedItem) => selectedItem.id === item.id)) {
            // Establecemos el error
            this.$set(this.formFields.items[index], 'errors', this.checkFieldErrors(field))
          } else {
            // Reseteamos los errores
            this.$set(this.formFields.items[index], 'errors', [])
          }
        })
      }

      // Lanzamos formulario
      this.onSubmit()
    },
    /**
     * When the user must click on cancel button
     */
    handleCancelButton() {
      this.routerGoTo()
    },
    /**
     * Tras pulsar sobre botón de "Editar" de opciones
     * de precios, abre "Dialog" con las opciones de los precios
     *
     * @param {Object} item - producto a editar
     */
    handleClickButtonEditPrices(item) {
      // Modificamos las propiedades pasadas al componente
      this.priceOptionsDialogProps = {
        item,
        availableTypes: this.isTAD ? [1, 2] : [0, 1, 2]
      }
      // Muestra diálogo de opciones de precios
      this.priceOptionsDialogVisible = true
    },
    /**
     * Tras la edición del precio de alguno de los items
     * "tocamos" el campo para validar este
     *
     * @param {Object} - "id" (vuelidate) y "item" (datos)
     */
    handleItemPricesChanges({ id, item }) {
      this.touchField(id)
      // coloco los errores dentro del campo (item)
      // para mostrarlo en la tabla
      this.$set(this.formFields.items[item.index], 'errors', this.checkFieldErrors(id))
    },
    /**
     * Se modifican los items seleccionados
     *
     * @param {Array} items - elementos seleccionados
     */
    handleChangeSelectedItems(items) {
      this.selectedItems = items
    },
    /**
     * Evento lanzado tras modificar las opciones de
     * precios de alguno de los items
     *
     * @param {Array} prices - opciones de precios
     */
    handlePriceOptionsDialogEvent(prices) {
      // Ocultamos diálogo de opciones de precios
      this.priceOptionsDialogVisible = false

      // Tomamos el item que queremos editar
      const { item } = this.priceOptionsDialogProps
      // Buscamos dentro del array cuál es el elemento
      // seleccionado y modificamos valores
      const indexItem = this.formFields.items.findIndex((dish) => {
        return dish.id === item.id
      })

      // Colocamos los nuevos valores
      if (indexItem > -1) {
        this.$set(this.formFields.items[indexItem], 'prices', prices)
      }
    },
    /**
     * Obtiene todos los datos necesarios para la vista
     */
    async getEveryNeededData() {
      try {
        // Datos de la categoría (origen) con la que tratamos
        this.sourceCategoryData = await getCategoryById(this.sourceCategoryId)
        // Datos de la categoría (destino) con la que tratamos
        this.targetCategoryData = await getCategoryById(this.targetCategoryId)

        // Determinamos si las cartas padres son "menús" o "TAD"
        if (this.isCategoryCloning) {
          this.isMenu = Boolean(get(this.targetCategoryData, 'price', null))
          this.isTAD =
            get(this.targetCategoryData, 'type', MENUS_TYPES.place.value) ===
            MENUS_TYPES.takeAway.value
        } else {
          const parentCategoryData = await getParentCategoryByChildId(this.targetCategoryId)
          this.isMenu = Boolean(get(parentCategoryData, 'price', null))
          this.isTAD =
            get(parentCategoryData, 'type', MENUS_TYPES.place.value) === MENUS_TYPES.takeAway.value
        }

        if (this.sourceCategoryData && this.targetCategoryData) {
          await this.setInitialProductsData(this.sourceCategoryData.id)
        } else {
          throw new Error('No se puede realizar la clonación de la categoría indicada')
        }
        // Seteamos valores
        this.formFields.categoryName = this.sourceCategoryData.name
      } catch (error) {
        // show error
        this.handleError(error.message)
        // Redirigimos al punto de donde venimos
        this.redirectToList()
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Obtenemos todos los datos de los items (productos)
     * que vamos a editar
     *
     * @param {string} id - UID category
     */
    async setInitialProductsData(id) {
      // Platos de la categoría origen
      this.sourceCategoryDishesData = await getEveryDishesByCategoryId(id)
      // Items de la tabla
      this.formFields.items = cloneDeep(
        this.sourceCategoryDishesData.map((dish, index) => {
          let currentPrices = get(dish, `prices[${this.sourceCategoryId}]`, null)

          if (this.isMenu) {
            // Categoría pertenece a un menú, no lleva precios
            currentPrices = null
          }

          return {
            index,
            id: dish.id,
            name: dish.name,
            prices: currentPrices
          }
        })
      )
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Muestra alerta de clonación
      this.showQuestionAlert()
    },
    /**
     * Muestra diálogo de aprobación de la clonación
     */
    showQuestionAlert() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          // Loading...
          this.formProcessing = true
          try {
            if (this.type === ACTION_TYPE.category) {
              // Creamos categoría y asociamos productos a ella
              const category = await this.createNewCategory()
              // Añadimos (actualizamos) nuevos precios a los productos
              this.updateDishesPrices(category.id)
            } else if (this.type === ACTION_TYPE.products) {
              // Añadimos (actualizamos) nuevos precios a los productos
              this.updateDishesPrices(this.targetCategoryData.id)
            }

            // Se indica que el proceso fue correcto
            this.modifyAppAlert({
              text: 'La clonación se realizó correctamente',
              visible: true
            })
            // Redirigimos al punto de donde venimos
            this.redirectToList()
          } catch (error) {
            // show error
            this.handleError(error.message)
          } finally {
            this.formProcessing = false
          }
        },
        actionButtonText: 'Clonar',
        text: '¿Estas seguro de haber seleccionado los productos que deseas clonar?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Crea nueva categoría a clonar (también se incluye traducción)
     *
     * @param {data} - datos a almacenar
     * @return {Object} - categoría creada
     */
    async createNewCategory() {
      // Configuraciones del establecimiento
      const placeConfig = get(this.placeAddonsSetupByUser, ADDONS.place, {})
      // Items seleccionados con sus datos
      const selectedDishes = this.getSelectedItems()
      // Creamos nueva categoría
      const { category } = await createCategory(
        {
          dishes: selectedDishes.reduce((sumItems, item) => {
            sumItems[item.id] = true
            return sumItems
          }, {}),
          name: this.formFields.categoryName,
          places: { [this.placeData.id]: true },
          parentId: this.targetCategoryData.id
        },
        null,
        {
          additionalLanguages: placeConfig.additionalLanguages,
          defaultLanguage: placeConfig.defaultLanguage
        }
      )

      return category
    },
    /**
     * Actualizamos los precios de los productos seleccionados
     * con la categoría indicada
     *
     * @param {string} categoryId - UID category
     */
    async updateDishesPrices(categoryId) {
      const selectedDishes = this.getSelectedItems()

      return Promise.all(
        selectedDishes.map(async (item) => {
          const indexItem = this.sourceCategoryDishesData.findIndex((dish) => {
            return dish.id === item.id
          })

          await updateDishById({
            id: item.id,
            prices: {
              ...this.sourceCategoryDishesData[indexItem].prices,
              [categoryId]: item.prices
            },
            categories: [...(this.sourceCategoryDishesData[indexItem].categories || []), categoryId]
          })
        })
      )
    },
    /**
     * Redirigimos a la categoría donde nos encontrábamos
     */
    redirectToList() {
      this.routerGoTo()
    },
    /**
     * Nos quedamos solo con los datos seleccionados por el usuario
     * a la hora de clonar los productos
     *
     * @return {Array} - items seleccionados
     */
    getSelectedItems() {
      return this.selectedItems.reduce((sumSelectedItems, item) => {
        const indexItem = this.formFields.items.findIndex((formItem) => {
          return formItem.id === item.id
        })

        if (indexItem > -1) {
          sumSelectedItems.push(item)
        }

        return sumSelectedItems
      }, [])
    }
  },
  // Validations with Vuelidate
  validations() {
    const rules = {
      formFields: {
        categoryName: {},
        items: {
          required,
          minLength: minLength(1),
          $each: {
            prices: {
              withValues: (value, field) => {
                // Comprobamos que campos han sido seleccionados
                // para ser clonados y por tanto validados
                const selectedItems = this.getSelectedItems()
                const itemMustBeValidated =
                  selectedItems.length > 0
                    ? selectedItems.find((item) => {
                        return item.id === field.id
                      })
                    : false
                // El item pertenece a un menú (no lleva precio)
                const itemBelongToMenu = isNil(value) && this.isMenu
                // Todos los precios del item son mayores a 0
                const itemPricesValidated = !isNil(value)
                  ? Object.values(value).every((price) => {
                      return price.price > 0
                    })
                  : false
                // Solo validamos los campos seleccionados y se permiten
                // campos nulos si nos encontramos en un Menú
                return !itemMustBeValidated || itemBelongToMenu || itemPricesValidated
              }
            }
          }
        }
      }
    }

    if (this.isCategoryCloning) {
      rules.formFields.categoryName = {
        required
      }
    }

    return rules
  }
}
</script>
